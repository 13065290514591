.about-page {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

@media (min-width: 1200px) {
    .about-page {
        max-width: 1140px;
    }
}

@media (max-width: 1199px) {
    .about-page {
        max-width: 960px;
    }
}

@media (max-width: 991px) {
    .about-page {
        max-width: 720px;
    }
}

@media (max-width: 767px) {
    .about-page {
        max-width: 540px;
    }
}

.about-top-content {
    padding-top: 25%;
    line-height: 36px !important;
    font-family: "RobotoMono" !important;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
}

.about-top-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .about-portrait {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .about-top-text {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

.about-portrait {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.about-top-text {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.about-bottom {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

#colchuck {
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 3%;
    border-top-left-radius: 30% 30%;
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
}


.about-top-text h5 {
    font-family: "RobotoMono" !important;
    text-align: left;
    color: rgba(255,255,255,1);
    visibility: visible;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: bolder;
    line-height: 1.2;
    font-size: 1.25rem;
}

.about-top-text p {
    line-height: 36px !important;
    font-family: "RobotoMono" !important;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    color: var(--text);
    visibility: visible;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 1rem;
    margin-top: 3vh;
}

/* Snowflakes */

.snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial;
    text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
    0% {
        top: -10%
    }

    100% {
        top: 100%
    }
}

@-webkit-keyframes snowflakes-shake {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px)
    }

    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px)
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px)
    }
}

@keyframes snowflakes-fall {
    0% {
        top: -10%
    }

    100% {
        top: 100%
    }
}

@keyframes snowflakes-shake {
    0% {
        transform: translateX(0px)
    }

    50% {
        transform: translateX(80px)
    }

    100% {
        transform: translateX(0px)
    }
}

.center_box {
    text-align: center;
}

.snowflake {
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    -webkit-animation-name: snowflakes-fall,snowflakes-shake;
    -webkit-animation-duration: 10s,3s;
    -webkit-animation-timing-function: linear,ease-in-out;
    -webkit-animation-iteration-count: infinite,infinite;
    -webkit-animation-play-state: running,running;
    animation-name: snowflakes-fall,snowflakes-shake;
    animation-duration: 10s,3s;
    animation-timing-function: linear,ease-in-out;
    animation-iteration-count: infinite,infinite;
    animation-play-state: running,running
}

.snowflake:nth-of-type(0) {
    left: 1%;
    -webkit-animation-delay: 0s,0s;
    animation-delay: 0s,0s
}

.snowflake:nth-of-type(1) {
    left: 10%;
    -webkit-animation-delay: 1s,1s;
    animation-delay: 1s,1s
}

.snowflake:nth-of-type(2) {
    left: 20%;
    -webkit-animation-delay: 6s,.5s;
    animation-delay: 6s,.5s
}

.snowflake:nth-of-type(3) {
    left: 30%;
    -webkit-animation-delay: 4s,2s;
    animation-delay: 4s,2s
}

.snowflake:nth-of-type(4) {
    left: 40%;
    -webkit-animation-delay: 2s,2s;
    animation-delay: 2s,2s
}

.snowflake:nth-of-type(5) {
    left: 50%;
    -webkit-animation-delay: 8s,3s;
    animation-delay: 8s,3s
}

.snowflake:nth-of-type(6) {
    left: 60%;
    -webkit-animation-delay: 6s,2s;
    animation-delay: 6s,2s
}

.snowflake:nth-of-type(7) {
    left: 70%;
    -webkit-animation-delay: 2.5s,1s;
    animation-delay: 2.5s,1s
}

.snowflake:nth-of-type(8) {
    left: 80%;
    -webkit-animation-delay: 1s,0s;
    animation-delay: 1s,0s
}

.snowflake:nth-of-type(9) {
    left: 90%;
    -webkit-animation-delay: 3s,1.5s;
    animation-delay: 3s,1.5s
}
