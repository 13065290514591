.footer-container {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    padding-top: 4rem;
    padding-bottom: 2rem;
    padding-left: 10%;
    padding-right: 10%;
}

.footer-border {
    width: 100%;
    border-top: 1px solid rgba(255,255,255,0.3);
}

.footer-info {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;

    justify-content: space-between;
    text-align: center;
}

.footer-info-head {
    text-align: center;
}

.footer-info-head {
    color: rgba(255,255,255,0.8) !important;
}

#media ul {
    display: flex;
    flex-direction: row;

    gap: 50px;
}

/* footer */
  .footer-info h4 {
    font-size: 18px;
  }

  @media(max-width: 500px) {
    .footer-info h4 {
      font-size: 14px;
    }
  }

  .footer-info a {
    text-decoration: none;
    background-color: transparent;

    color: var(--main-color) !important;
  }

  .footer-info a:hover {
    text-decoration: underline;
  }
  
  .footer-info li {
    color: #fff;
    font-size: 18px;
    padding-left: 20px;
  }
  
  #medium::before {
    display: inline-block;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-right: 6px;
    /* background: var(--text); */
  }
  
  
  #ig::before {
    display: inline-block;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-right: 6px;
    background: #793ab4;
  }
  
  
  #li::before {
    display: inline-block;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-right: 6px;
    background: #0e76a8;
  }
  
  #beli::before {
    display: inline-block;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-right: 6px;
    background: #7de08e;
  }
  
  #git::before {
    display: inline-block;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-right: 6px;
    background: #7dbbe6;
  }
  
  #media, #code {
    text-align: right;
  }
  
  #media ul {
    list-style: none;
  }
  
  #media ul li {
    display: inline-block;
  }
  
  @media(max-width: 610px) {
    .footer-info {
      justify-content: center;
    }
    #media, #code {
      text-align: center;
    }
    #media ul {
      padding: 0px;
    }
  }
