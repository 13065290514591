.header {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'RobotoMonoLight';
}

.header h1 {
  font-weight: 200;
  text-align: center;
  letter-spacing: -2px;
  line-height: 70px;
  font-size: 2.5rem;
}

.header h1 {
    width: 24em;
    top: 50%;
    margin: auto;
    border-right: 2px solid rgba(255, 255, 255, .3);;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
}

@media(max-width: 768px) {
  .header h1 {
    font-size: 26px;
  }
}

@media(max-width: 500px) {
  .header h1 {
    font-size: 23px;
  }
}

.typewriter-animation h1:nth-child(1) {
    width: 6em;
    -webkit-animation: type 1s steps(40, end);
    animation: type 1s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .typewriter-animation h1:nth-child(2) {
    width: 12em;
    opacity: 0;
    -webkit-animation: type2 1.25s steps(40, end);
    animation: type2 1.25s steps(40, end);
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .typewriter-animation h1:nth-child(3) {
    width: 18em;
    opacity: 0;
    animation: type3 1.75s steps(60, end), blink .5s step-end infinite alternate;
    animation-delay: 2.25s;
    animation-fill-mode: forwards;

    color: var(--main-color) !important;
  }

  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right-color: rgba(255, 255, 255, 0.75);
    }
    100% { 
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right-color: rgba(255, 255, 255, 0.75);
    }
    100% { 
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right-color: rgba(255, 255, 255, 0.75);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right-color: rgba(255, 255, 255, 0.75);  }
    100% {
      opacity: 1;
      border: none;
    }
  }

  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  
  @-webkit-keyframes blink {
    50% {
      border-color: transparent;
    }
  }

/* scroll anim */
.scroll-down {
    position: absolute;
    left: 50%;
    bottom: 100px;
    display: block;
    text-align: center;
    font-size: 20px;
    z-index: 0;
    text-decoration: none;
    text-shadow: 0;
    width: 13px;
    height: 13px;
    border-bottom: 2px solid var(--text-color);
    border-right: 2px solid var(--text-color);
    -webkit-transform: translate(-50%, 0) rotate(45deg);
    animation: fade_move_down 3s cubic-bezier(0.19, 1, 0.22, 1) infinite;
  }
  
  @keyframes fade_move_down {
    0% {
      transform: translate(0, -20px) rotate(45deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(0, 20px) rotate(45deg);
      opacity: 0;
    }
  }
